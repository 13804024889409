import styled from 'styled-components';

export const DivWrapperLocationsModal = styled.div`
    display: grid;
    height: calc(95vh - 170px);
    width: 95vw;
    grid-template-columns: 100%;
    grid-template-rows: 32px 1fr;
    gap: 10px;
`

export const InputFilterWrapperSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 192px 146px;
    grid-template-rows: 100%;
    box-sizing: border-box;
    gap: 15px;
`