import { ContainerSC, HeaderWrapperSC, NavMenuWrapperSC, SettingsButtonWrapperSC, useStyles } from './styles';
import { Logo } from '../Logo/Logo';
import { useGeneralStore } from '../../store';
import { Settings } from '../Settings/Settings';
import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { projectTheme } from 'src/theme';
import { CustomBreadcrumb } from './components/Breadcrumb/Breadcrumb';
import { useEffect } from 'react';
import { Title } from '../Title/Title';
import useGetUrlParams from '../../hooks/useGetUrlParams';
import { useGetLocations } from '../../api/useGetLocations';


export const Header = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const plId = useGetUrlParams('plId')
    const projectId = useGetUrlParams('projectId')
    useGetLocations(projectId)
    const {
        locationsById,
        projectsById,
        selectedLocation,
        selectedProject,
        selectedLocationId,
        selectedProjectId,
        locales,
        currentData,
        services
    } = getGeneralStore();

    useEffect(() => {
        if (selectedLocationId && locationsById) {
            updateGeneralStore({selectedLocation: Boolean(locationsById[selectedLocationId]) ? locationsById[selectedLocationId] : null})
        }
        if (selectedProjectId && projectsById) {
            updateGeneralStore({selectedProject: Boolean(projectsById[selectedProjectId]) ? projectsById[selectedProjectId] : null})
        }

    }, [selectedLocationId, selectedProjectId, locationsById, projectsById]);

    const onLogout = () => {
        localStorage.removeItem('xtoken');
        services?.['core/admin-service'].LOGOUT_URL &&
            window.location.replace(
                `${services?.['core/admin-service'].LOGOUT_URL}?next=/sso-login?domain=https://${locales?.domain}&next=/sso/ajax-token?next=` +
                    window.location.href,
            );
    };


    return (
        <HeaderWrapperSC
            $bgColor={projectTheme.colorBrandBackground2}
            // $color={themeDarker}
        >
            <NavMenuWrapperSC>
                <Logo logo={locales?.logo_url} />
                <CustomBreadcrumb />
            </NavMenuWrapperSC>
            <ContainerSC>
                <Title>{`${selectedProject ? selectedProject.name : ''}${selectedProject && selectedLocation ? ' / ' : ''}${selectedLocation ? selectedLocation.name : ''}`}</Title>
            </ContainerSC>
            <SettingsButtonWrapperSC>
                <Settings />
            </SettingsButtonWrapperSC>
            {currentData?.username && (
                <div className={styles.wrapper}>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance="transparent">{currentData?.username}</MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
            )}
        </HeaderWrapperSC>
    );
};
