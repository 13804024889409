import styled from 'styled-components';

export const PanelTitleSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: max-content;
    grid-template-rows: max-content;
    font-size: 1.25rem;
    font-weight: 500;
`