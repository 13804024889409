import {styled} from "styled-components";
import {
    TableHeaderCell,
    TableRow,
} from "@fluentui/react-components";
import {projectTheme} from "../../theme";

const { colorNeutralBackground1Hover } = projectTheme;

export const ProjectsWrapperSC = styled.section<{
    $bgcolor: string;
    $color: string;
}>`
    display: grid;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${(p) => p.$bgcolor};
    color: ${(p) => p.$color};
    padding: 10px;
    grid-template-columns: 100%;
    grid-template-rows: 32px 1fr;
    box-sizing: border-box;
    gap: 10px;
`

export const InputFilterWrapperSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 192px 146px;
    grid-template-rows: 100%;
    box-sizing: border-box;
    gap: 15px;
`

export const TableWrapperSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    overflow: hidden;
`

export const CustomTableRowSC = styled(TableRow)<{
    $bg: string;
    $isImportant?: boolean;
}>`
    background: ${({ $bg, $isImportant }) => (`${$bg} ${$isImportant ? '!important' : ''}`)};
    cursor: pointer;
`

export const CustomHeaderCellSC = styled(TableHeaderCell)`
    //font-size: 16px;
    text-overflow: ellipsis;
    font-weight: 600;
    &:hover{
        background: ${colorNeutralBackground1Hover};
    }
`