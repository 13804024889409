import React, {FC} from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { Label } from '@fluentui/react-components';
import { IPropsCodeWrapper } from '../interfaces';
import {
    bbedit
} from '@uiw/codemirror-theme-bbedit';
import { useGeneralStore } from '../../../store';

const CodeWrapper:FC<IPropsCodeWrapper> = (props) => {
    const {
        label,
        value,
        keyName
    } = props
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    const handleEditorChange = (value: string, viewUpdate: any) => {

        if (selectedConnectorDetail) {
            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: JSON.parse(value)
                }
            })
        }

    }


    return (
        <div>
            <Label >
                {label}
            </Label>
            <CodeMirror
                value={JSON.stringify(value, null, 2)}
                height="max-content"
                extensions={[json()]}
                theme={bbedit}
                onChange={handleEditorChange}
            />
        </div>
    )
};

export default CodeWrapper;