import { useEffect } from 'react';

import { useGeneralStore } from 'src/store';
import { getServicesStructure } from 'src/tools';

import { useGetLocales } from '../api/useGetLocales';
import { useGetCurrent } from '../api/useGetCurrent';
import { useGetProjects } from '../api/useGetProjects';

export const useInitialRequests = () => {
    useGetLocales();
    useGetCurrent();
    useGetProjects();
    const { updateGeneralStore, generalStore } = useGeneralStore();

    useEffect(() => {
        if (!generalStore.locales) {
            return;
        }

        if (Object.values(generalStore.locales).length > 0) {
            const services = getServicesStructure(generalStore.locales.services);
            updateGeneralStore({ services });
        }
    }, [generalStore.locales, updateGeneralStore]);
};
