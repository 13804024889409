import { ITenant } from '../../../types';

export const getItemsAfterFilter = ({
                                        array,
                                        isChecked1,
                                        isChecked2
                                    }: {
    array:ITenant[],
    isChecked1: boolean,
    isChecked2: boolean
}) => {
    // const arrayAfterChecked1 = array.filter((it) => it.is_active === !isChecked1)
    // const arrayAfterChecked2 = arrayAfterChecked1.filter((it) => it.is_actual === !isChecked2)

    const arrayAfterChecked1 = array.filter((it) =>  !isChecked1 ? it.is_active === !isChecked1: true)
    const arrayAfterChecked2 = arrayAfterChecked1.filter((it) => !isChecked2 ?  it.is_actual === !isChecked2 : true)
    return arrayAfterChecked2
}