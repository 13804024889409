import React, { FC, ChangeEvent } from 'react';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Field, Label, makeStyles, useId, InputOnChangeData } from '@fluentui/react-components';
import type { DatePickerProps } from "@fluentui/react-datepicker-compat";
import { useStyles } from '../styles';
import { IPropsCalendarBox } from '../interfaces';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useGeneralStore } from '../../../store';


const CalendarBox: FC<IPropsCalendarBox> = (props) => {
    const {
        label,
        dateG = '',
        keyName,
        value
    } = props
    const styles = useStyles();
    const inputId = useId("input");
    const { t } = useTranslation();
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    const onChange = (date: Date | null | undefined) => {
        if (selectedConnectorDetail && date) {
            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
                }
            })
        }
    }

    const formatDate = (date?: Date | undefined) => {
        if (!date) {
            return ''
        }
        return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    }

    return (
        <div>
            <Label  htmlFor={inputId}>
                {`${t('Select a ')}${label.toLowerCase()}`}
            </Label>
            <Field id={inputId}>
                {
                    keyName === 'date_to' ?
                        <DatePicker
                            className={styles.control}
                            placeholder={label}
                            onSelectDate={onChange}
                            formatDate={formatDate}
                            minDate={new Date(dateG)}
                            value={value ? new Date(value) : null}
                        /> :
                        <DatePicker
                            className={styles.control}
                            placeholder={label}
                            onSelectDate={onChange}
                            formatDate={formatDate}
                            maxDate={new Date(dateG)}
                            value={value ? new Date(value) : null}
                        />
                }

            </Field>
        </div>
    );
}

export default CalendarBox;