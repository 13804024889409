import {projectTheme} from "../../../theme";
import {CustomTableRowSC} from "../../Projects/styles";
import {TableCell, TableCellLayout} from "@fluentui/react-components";
import { FC} from "react";

import formatText from '../../../tools/formatText';
import { IPropsRenderRow } from '../../Table/interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckboxUncheckedRegular, CheckmarkSquareRegular } from '@fluentui/react-icons';
import { makeStyles, shorthands } from "@fluentui/react-components";
import { TableCellLayoutSC } from '../../Table/styles';
import { useGeneralStore } from '../../../store';

const useClasses = makeStyles({

    icon24: { fontSize: "24px" },

});

export const RenderRow: FC<IPropsRenderRow> = ({index, style, data}) => {
    const { t } = useTranslation();
    const classes = useClasses();
    const {item, selected, appearance, onClick, onKeyDown} = data[index];
    const {
        colorBrandBackground2,
        colorNeutralForegroundStaticInverted,
        colorBrandBackground2Pressed
    } = projectTheme;
    const { getGeneralStore } = useGeneralStore();
    const {  selectedTenant } = getGeneralStore();


    let bg = index % 2 !== 0 ? colorBrandBackground2 : colorNeutralForegroundStaticInverted
    let isImportant = false

    if (selectedTenant && selectedTenant.id === item.id.label ) {
        bg = colorBrandBackground2Pressed
        isImportant = true
    }

    const listComponents = Object.keys(item).map((_item, i) => {


        if (_item === 'is_actual') {
            return (
                <TableCell
                    key={`item table row${i}`}
                >
                    <TableCellLayoutSC>
                        { item[_item].label ? <CheckmarkSquareRegular className={classes.icon24} />
                            : <CheckboxUncheckedRegular className={classes.icon24} />}


                    </TableCellLayoutSC>

                </TableCell>
            )
        }

        else if (_item === 'is_deleted') {
            return (
                <TableCell
                    key={`item table rowrte ${i}`}
                >
                    <TableCellLayoutSC>
                        { item[_item].label ? <CheckmarkSquareRegular className={classes.icon24} />
                            : <CheckboxUncheckedRegular className={classes.icon24} />}


                    </TableCellLayoutSC>

                </TableCell>
            )
        }

        return (
            <TableCell
                key={`item table row${i}`}
            >
                <TableCellLayoutSC>

                    {formatText(item[_item].label, 30)}
                </TableCellLayoutSC>

            </TableCell>
        )
    })

    return (
        <CustomTableRowSC
            key={`item.file.label${index}`}
            aria-rowindex={index + 2}
            style={style}
            onKeyDown={onKeyDown}
            onClick={onClick}
            appearance={appearance}
            $bg={bg}
            $isImportant={isImportant}
        >
            {listComponents}
        </CustomTableRowSC>
    )
}