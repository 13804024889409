import { DivWrapperSpinnerSC } from './styles';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@fluentui/react-components';


export const CustomSpinner = (props:{text: string, isShow: boolean}) => {
    const { t } = useTranslation();
    const {
        text,
        isShow
    } = props

    if (!isShow) {
        return null
    }
    return (
        <DivWrapperSpinnerSC>
            <Spinner />
            <div >{`${t(text)}...`}</div>
        </DivWrapperSpinnerSC>
    );
};