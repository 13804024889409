import { Button, Popover, PopoverProps, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import React, { FC, ReactElement } from 'react';
import { ICustomPopoverProps } from './interfaces';
import { ChevronDownFilled } from "@fluentui/react-icons";
import { DivWrapperTextSC } from './styles';
import getFormatedText from '../../tools/getFormatedText';

export const CustomPopover: FC<ICustomPopoverProps> = (props) => {

    const {
        open,
        onOpenChange,
        label,
        children
    } = props



    return <Popover
        positioning={'above'}
        open={open}
        onOpenChange={onOpenChange}
    >
        <PopoverTrigger disableButtonEnhancement>
            <Button
                icon={<ChevronDownFilled />}
                iconPosition="after"
            >
                <DivWrapperTextSC>
                    {getFormatedText(label, 32)}
                </DivWrapperTextSC>

            </Button>
        </PopoverTrigger>

        <PopoverSurface tabIndex={-1}>
            {
                children
            }

        </PopoverSurface>
    </Popover>;
};