import { IConnector, ITenant } from '../../../types';
import { DateTime, Interval } from 'luxon';
import processNullToDate from '../../../tools/processNullToDate';

interface IFuncById {
    [index: string]: (e: IPropsFunc) => ITenant[];
}

interface IPropsFilterTenants {
    tenants: ITenant[];
    connectors: IConnector[];
    statusFilter: string;
}

interface IPropsFunc {
    tenants: ITenant[];
    connectors: IConnector[];
}

export const filterTenants = ({ tenants, connectors, statusFilter }: IPropsFilterTenants) => {
    return funcById[`get${statusFilter}`]({ tenants, connectors });
};

const getObjCountConnectorsForTenants = (tenants: ITenant[], connectors: IConnector[]) => {
    let objTenantsById: {
        [id: number]: {
            id: number;
            connectorsCount: number;
            tenant: ITenant;
            connectors: IConnector[];
        };
    } = {};

    connectors.forEach((connector) => {
        tenants.forEach((tenant, i) => {
            if (!objTenantsById[tenant.id]) {
                objTenantsById[tenant.id] = {
                    id: tenant.id,
                    connectorsCount: 0,
                    tenant,
                    connectors: [],
                };
            }

            if (tenant.id === connector.tenant_id) {
                objTenantsById[tenant.id].connectorsCount = objTenantsById[tenant.id].connectorsCount + 1;
                objTenantsById[tenant.id].connectors.push(connector);
            }
        });
    });
    return objTenantsById;
};

const funcById: IFuncById = {
    getAll: ({ tenants, connectors }) => {
        return tenants;
    },
    getWithActualConnectors: ({ tenants, connectors }) => {
        let newTenants: ITenant[] = [];
        let arrayIds: number[] = [];
        connectors.forEach((connector) => {
            tenants.forEach((tenant, i) => {
                if (tenant.id === connector.tenant_id) {
                    if (arrayIds.indexOf(tenant.id) === -1) {
                        if (!connector.date_to) {
                            newTenants.push(tenant);
                        }
                        if (connector.date_to) {
                            if (DateTime.now().toMillis() <= DateTime.fromISO(connector.date_to).toMillis()) {
                                newTenants.push(tenant);
                            }
                        }
                        arrayIds.push(tenant.id);
                    }
                }
            });
        });
        return [...newTenants];
    },
    getWithoutConnectors: ({ tenants, connectors }) => {
        let objTenantsById: {
            [id: number]: {
                id: number;
                connectorsCount: number;
                tenant: ITenant;
                connectors: IConnector[];
            };
        } = getObjCountConnectorsForTenants(tenants, connectors);

        const newTenants: ITenant[] = tenants.filter((item, i) => {
            return objTenantsById[item.id].connectorsCount === 0;
        });

        return [...newTenants];
    },

    // TODO Доделать функционал
    getWithSimultaneousConnectors: ({ tenants, connectors }) => {
        let objTenantsById: {
            [id: number]: {
                id: number;
                connectorsCount: number;
                tenant: ITenant;
                connectors: IConnector[];
            };
        } = getObjCountConnectorsForTenants(tenants, connectors);

        // const mainInterval = Interval.fromISO(`2024-07-05/null`);
        // const discountInterval = Interval.fromISO(`2024-07-06/2024-07-11`);
        // const mainInterval2 = Interval.fromISO(`2024-07-05/2024-07-10`);
        // const discountInterval2 = Interval.fromISO(`2024-07-11/2024-07-14`);

        const newTenants: ITenant[] = tenants.filter((item, i) => {
            let status = false;
            if (objTenantsById[item.id].connectorsCount >= 2) {
                objTenantsById[item.id].connectors.forEach((item1, i) => {

                    if (!item1.date_from && !item1.date_to) {
                        status = true;
                    }
                    else {

                        objTenantsById[item.id]?.connectors.forEach((item2, i2) => {

                            if (i !== i2) {

                                if (DateTime.fromISO(processNullToDate(item1.date_from, 'dateFrom')).toMillis() >= DateTime.fromISO(processNullToDate(item1.date_to, 'dateTo')).toMillis() &&

                                    DateTime.fromISO(processNullToDate(item2.date_from, 'dateFrom')).toMillis() >= DateTime.fromISO(processNullToDate(item2.date_to, 'dateTo')).toMillis()
                                ) {
                                    const mainInterval = Interval.fromISO(`${processNullToDate(item1.date_from, 'dateFrom')}/${processNullToDate(item1.date_to, 'dateTo')}`);
                                    const compareInterval = Interval.fromISO(`${processNullToDate(item2.date_from, 'dateFrom')}/${processNullToDate(item2.date_to, 'dateTo')}`);

                                    if (mainInterval.intersection(compareInterval)) {

                                        status = true;
                                    }
                                }

                            }
                        });
                    }
                });
            }
            return status;
        });

        return [...newTenants];
    },
    getWithSimultaneousActualConnectors: ({ tenants, connectors }) => {
        let objTenantsById: {
            [id: number]: {
                id: number;
                connectorsCount: number;
                tenant: ITenant;
                connectors: IConnector[];
            };
        } = getObjCountConnectorsForTenants(tenants, connectors);

        const newTenants: ITenant[] = tenants.filter((item, i) => {
            let status = false;
            if (objTenantsById[item.id].connectorsCount >= 2) {
                // let status

                objTenantsById[item.id].connectors.forEach((item, i) => {

                    status =
                        !item.date_to || DateTime.now().toMillis() <= DateTime.fromISO(item.date_to || '').toMillis();
                });
            }
            return status;
        });

        return newTenants;
    },
};
