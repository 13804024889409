import { MessageBarGroup, Portal } from '@fluentui/react-components';
import { DivWrapperAlertSC, useStyles } from './styles';
import { useGeneralStore } from '../../store';
import { useEffect } from 'react';
import { ItemMessage } from './components/ItemMessage';

export const Alert = () => {
    const styles = useStyles();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { messageList } = getGeneralStore();

    return (
        <DivWrapperAlertSC>
            <MessageBarGroup className={styles.messageBarGroup}>
                {messageList.map((item, i) => {
                    return <ItemMessage key={`list mess - ${i}`} item={item} />;
                })}
            </MessageBarGroup>
        </DivWrapperAlertSC>
    );
};
