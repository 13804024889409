import {StyledLinkSC, StyledLogoSC} from "./styles";
import {FC} from "react";

export const Logo:FC<{
    logo:string | undefined;
}> = (props) => {
    const {
        logo
    } = props

    return (
        <StyledLinkSC to="/">
            <StyledLogoSC $logo={logo ? logo : ''} />
        </StyledLinkSC>
    );
};