import {projectTheme} from "../../../theme";
import {CustomTableRowSC} from "../../Projects/styles";
import {TableCell, TableCellLayout} from "@fluentui/react-components";
import { FC} from "react";

import formatText from '../../../tools/formatText';
import { IPropsRenderRow } from '../../Table/interfaces';
import { EditFilled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { Button } from "@fluentui/react-components";
import { useGeneralStore } from '../../../store';


export const RenderRowConnectors: FC<IPropsRenderRow> = ({index, style, data}) => {
    const { t } = useTranslation();
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();

    const {
        connectors
    } = getGeneralStore()

    const {item, selected, appearance, onClick, onKeyDown} = data[index];
    const {colorBrandBackground2, colorNeutralForegroundStaticInverted} = projectTheme;

    const bg = index % 2 !== 0 ? colorBrandBackground2 : colorNeutralForegroundStaticInverted

    const onClickButton = () => {

        const found = connectors.find((itemConn, i) => itemConn.id === Number(item.id.label))
        updateGeneralStore({selectedConnector: found ? found : null})
    }

    const listComponents = Object.keys(item).map((_item, i) => {

        if (_item === 'edit') {
            return (
                <TableCell
                    key={`item table row${i}`}
                >
                    <TableCellLayout>

                        <Button onClick={onClickButton} icon={<EditFilled />} />
                    </TableCellLayout>

                </TableCell>
            )
        }

        return (
            <TableCell
                key={`item table row${i}`}
            >
                <TableCellLayout>

                    {formatText(item[_item].label, 30)}
                </TableCellLayout>

            </TableCell>
        )
    })

    return (
        <CustomTableRowSC
            key={`item.file.label${index}`}
            aria-rowindex={index + 2}
            style={style}
            onKeyDown={onKeyDown}
            onClick={onClick}
            appearance={appearance}
            $bg={bg}
        >
            {listComponents}
        </CustomTableRowSC>
    )
}