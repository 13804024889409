import {createTableColumn} from "@fluentui/react-components";
import { IColumn } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';

export const columns:IColumn[] = [
    createTableColumn<IItemForTable>({
        columnId: "id",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.id.label).localeCompare(String(b.id.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "name",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.name.label).localeCompare(String(b.name.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "is deleted",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.is_deleted.label).localeCompare(String(b.is_deleted.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "is actual",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.is_actual.label).localeCompare(String(b.is_actual.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "connector count",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.connector_count.label).localeCompare(String(b.connector_count.label));
        },
    }),
];

export const columnsConnectors:IColumn[] = [
    createTableColumn<IItemForTable>({
        columnId: "id",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.id.label).localeCompare(String(b.id.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "connector type",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.connector_type.label).localeCompare(String(b.connector_type.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date from",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.date_from.label).localeCompare(String(b.date_from.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date to",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.date_to.label).localeCompare(String(b.date_to.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "tenant id",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.tenantId.label).localeCompare(String(b.tenantId.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "edit",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.tenantId.label).localeCompare(String(b.tenantId.label));
        },
    }),
];
