import styled from 'styled-components';


export const Wrapper = styled.div`
    width: 35vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const ContentWrapper = styled.div`
    flex-grow: 1;
`;

export const ButtonsWrapper = styled.div`
    display: grid;
    justify-content: end;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-template-rows: 100%;
    gap: 5px;
`;
