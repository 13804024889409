import { BaseInputs } from './BaseInputs';
import React, { FC, useEffect, useState } from 'react';
import { IPropsModalContentConnector } from '../interfaces';
import { useGetConnector } from '../../../api/useGetConnector';
import { CustomSpinner } from '../../Spinner/Spinner';
import { CustomInputs } from './CustomInputs';
import { DivSpinnerBox2SC, DivSpinnerBoxSC, DivWrapperButtonSC, DivWrapperInputListsSC } from '../styles';
import { Button, Divider, Spinner } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import DisplayList from './DisplayList';
import { useGetPointsOfSale } from '../../../api/useGetPointsOfSale';
import { useGeneralStore } from '../../../store';
import { compare } from '../../../tools/compare';
import { usePatchEditConnector } from '../../../api/usePatchEditConnector';
import { TConnectorReq } from '../../../types/connectors';
import { ArrowClockwiseFilled } from '@fluentui/react-icons';

export const EditConnectorModal: FC<IPropsModalContentConnector> = (props) => {
    const { connectorId, connectorType = '' } = props;
    const { t } = useTranslation();
    const { getGeneralStore } = useGeneralStore();

    const { selectedConnectorDetail, selectedLocationId, selectedConnectorDetailCTG } = getGeneralStore();
    const { isFetching, refetch: refetchConnector } = useGetConnector(connectorId, connectorType);
    const { isFetching: isFetching2, data } = useGetPointsOfSale({
        dateFrom: selectedConnectorDetail?.date_from ? selectedConnectorDetail?.date_from : '',
        dateTo: selectedConnectorDetail?.date_to ? selectedConnectorDetail?.date_to : '',
        limit: 10000,
        offset: 0,
        PLId: selectedLocationId ? selectedLocationId : 0,
        tenantId: selectedConnectorDetail?.tenant ? selectedConnectorDetail?.tenant : 0,
        connectorId: selectedConnectorDetail?.id ? selectedConnectorDetail?.id : 0,
    });
    const { isPending, mutate, data: data2, status } = usePatchEditConnector();

    useEffect(() => {
        refetchConnector();
    }, []);



    const onClick = () => {
        if (selectedConnectorDetail) {
            mutate(selectedConnectorDetail);
        }

        // hideModal()
    };

    return (
        <DivWrapperInputListsSC>
            <BaseInputs />
            <Divider />
            <CustomInputs />
            <Divider />
            <DisplayList list={data ? data.results : []} />
            <CustomSpinner text={'get connector'} isShow={isFetching} />
            <CustomSpinner text={'get points of sale'} isShow={isFetching2} />
            <DivWrapperButtonSC>
                <Button
                    appearance="primary"
                    onClick={onClick}
                    disabled={compare(selectedConnectorDetail, selectedConnectorDetailCTG)}
                    icon={isPending ? <DivSpinnerBox2SC>
                        <Spinner size={'extra-tiny'} />
                    </DivSpinnerBox2SC> : null}
                >
                    {t('Edit')}
                </Button>
            </DivWrapperButtonSC>
        </DivWrapperInputListsSC>
    );
};
