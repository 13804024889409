import { PanelTitleSC } from './styles';
import { FC } from 'react';
import { IPropsTitle } from '../../types';

export const Title: FC<IPropsTitle> = (props) => {
    const {
        children
    } = props



    return (
        <PanelTitleSC>
            {children}
        </PanelTitleSC>
    );
};