import React, { FC, useEffect, useState } from 'react';
import { Label, Option, PopoverProps } from '@fluentui/react-components';
import { CustomPopover } from '../../CustomPopover/CustomPopover';
import {
    DivBoxSearchSC, DivItemCheckboxSC,
    DivWrapperCheckBoxListSC, DivWrapperListPopoverForListTimesSC,
    DivWrapperListPopoverSC,
    DivWrapperListPopoverScrollSC, DivWrapperListSC,
} from '../styles';
import { Search } from '../../Search/Search';
import { IPropsCheckboxList } from '../interfaces';
import { Checkbox } from "@fluentui/react-components";
import type { CheckboxProps } from "@fluentui/react-components";
import getTimes from '../settings/times';
import { useGeneralStore } from '../../../store';
import { TConnectorReq } from '../../../types/connectors';

const CheckboxList:FC<IPropsCheckboxList> = (props) => {
    const {
        label,
        keyName,
        values
    } = props
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()
    const [open, setOpen] = useState<boolean>(false);
    const [strValue, setStrValue] = useState<string>('');

    useEffect(() => {
        setStrValue(values.join(','))
    }, [values]);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, _data) => {
        setOpen(_data.open || false);
        // setDataConnectorParams(data ? data : dataConnectorParams)
        // setSearchValue('');
    }

    const onChange = (time: number) => () => {
        if (selectedConnectorDetail) {
            let temp: number[] = [
                // @ts-ignore
                ...selectedConnectorDetail[keyName],
                time
            ]

            temp.sort((a, b) => {
                if (a > b) {
                    return 1
                }
                else if (a < b) {
                    return -1
                }
                return 0
            })


            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: [
                        ...temp
                    ]
                }
            })
        }
    }

    return (
        <DivWrapperCheckBoxListSC>
            <Label >{label}</Label>
            <CustomPopover
                open={open}
                onOpenChange={handleOpenChange}
                label={strValue}
            >
                <DivWrapperListPopoverForListTimesSC>
                    <DivWrapperListSC>
                        {
                            getTimes().map((item, i) => {

                                return(
                                    <DivItemCheckboxSC key={`listTimes-${i}`}>
                                        <Checkbox
                                            checked={values.includes(item)}
                                            onChange={onChange(item)}
                                            label={item}
                                        />
                                    </DivItemCheckboxSC>
                                )
                            })
                        }

                    </DivWrapperListSC>
                    {/*<DivWrapperListPopoverScrollSC>*/}
                    {/*    {dataConnectorParams.map((option, i) => (*/}

                    {/*        <Option onClick={onSelectOption(option.value)} value={option.value}*/}
                    {/*                key={`dropdown${id}-${i}`}>*/}
                    {/*            {option.name}*/}
                    {/*        </Option>*/}
                    {/*    ))}*/}
                    {/*</DivWrapperListPopoverScrollSC>*/}

                </DivWrapperListPopoverForListTimesSC>
            </CustomPopover>
        </DivWrapperCheckBoxListSC>
    );
}

export default CheckboxList;