import { IConnector, ITenant } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';


const getItemsForTable = (list: IConnector[], filterValue: string):IItemForTable[]  => {

    return list
        .filter((it) => it.connector_type.toLowerCase().includes(filterValue.toLowerCase()) ||
            String(it.id).toLowerCase().includes(filterValue.toLowerCase()))
        .map((item) => {

            return {

                id: {
                    label: item.id,
                },
                connector_type: {
                    label: item.connector_type,
                },
                date_from: {
                    label: item.date_from ? item.date_from : '-',
                },
                date_to: {
                    label: item.date_to ? item.date_to : '-',
                },
                tenantId: {
                    label: item.tenant_id,
                },
                edit: {
                    label: 'adit'
                }

            };
        });
}

export default getItemsForTable;