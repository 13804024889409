import styled from 'styled-components';

export const DivWrapperSpinnerSC = styled.div`
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1000;
    align-content: center;
    justify-content: center;
`

