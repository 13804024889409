export type EmptyObjectType = {};
export type EmptyArrayType = [];
export type Noop = (...args: any[]) => void;
export type Falsy = (...args: any[]) => false;
export type Nullable = (...args: any[]) => null;

export const noop = Object.freeze(function () {});
export const foo = Object.freeze(function (value: unknown) {
    return value;
});
export const falsy = Object.freeze(function () {
    return false;
});
export const nullable = Object.freeze(function () {
    return false;
});
export const handlePreventDefault = Object.freeze(function (event: Event) {
    event.preventDefault();
    event.stopPropagation();
});

export const emptyArray = Object.freeze([]);
export const emptyObject = Object.freeze({});
