import { Wrapper } from './styles';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbDivider,
    BreadcrumbButton,
} from "@fluentui/react-components";
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IBreadcrumbProps } from './interfaces';
import { Body } from '../../../Modal/styles';

export const CustomBreadcrumb: FC = () => {

    const location = useLocation()
    const [itemsCrumbs, setItemsCrumbs] = useState<{
        text:string;
        href: string;
        key: string;
    }[]>([]);
    useEffect(() => {

        const nameMap = {
            projects: 'Projects',
            project: `Project`,
            pl: 'Location',
            plan: 'Plan',
        }
        let sumHref = '';

        let _item = location.pathname.substring(1).split('/')
            .reduce(
                (acc: any[], item: string, i: number, arr: string[]) => {
                    if (i % 2 !== 0) {
                        const key = arr[i - 1] as keyof typeof nameMap;
                        const obj = { text: nameMap[key], key, href: `${[arr[i - 1]]}/${item}` };

                        return [...acc, obj];
                    } else {
                        return acc;
                    }
                },
                [{ text: nameMap['projects'], key: 'projects', href: '' }],
            )
            .map((item: any, i: number) => {
                if (i !== 0) {
                    sumHref += `/${item.href}`;
                } else {
                    sumHref += `${item.href}`;
                }
                return { ...item, href: sumHref };
            });
        setItemsCrumbs(_item)
    }, [location]);


    return (
        <Wrapper>
            <Breadcrumb aria-label="Breadcrumb default example">
                {
                    itemsCrumbs.map((item, i) => {
                           return(
                               <React.Fragment key={`crumb${i}`}>
                                   <BreadcrumbItem >
                                       <BreadcrumbButton current={i === itemsCrumbs.length - 1} href={item.href === '' ?
                                           '/' : item.href}>{item.text}</BreadcrumbButton>
                                   </BreadcrumbItem>
                                   <BreadcrumbDivider />
                               </React.Fragment>
                           )
                       })
                }


            </Breadcrumb>
        </Wrapper>
    );
};