// import { camelToSnakeCase } from 'src/settings';
import {
    IConnector,
    ICurrentData, IDataConnectors, IDataStructureService, IDataTenants, ILocation,
    ILocationsById, IMessageList, IProject,
    IProjects,
    IProjectsById,
    ITenant, ITenant2Place,
    Locales,
    TLocations,
    UrlsByServices,
} from 'src/types';

import { useStore } from './useStore';
import { TConnectorReq } from '../types/connectors';
// import { GlobalSearchStore } from './types';

// console.log(import.meta);
// console.log(this);
// const [filenameKey] = __filename.replace('/', '').split('.');
// const STORE_KEY = `${camelToSnakeCase(filenameKey).toUpperCase()}_STORE_KEY`;
const STORE_KEY = `GENERAL_STORE_KEY`;

type GeneralLocales = (Locales & { domain: string }) | undefined;

export const initialState = {
    token: '',
    locales: {} as GeneralLocales,
    services: {} as UrlsByServices,
    globalSpinners: [] as string[],
    currentData: {} as ICurrentData,
    projects: [] as IProjects,
    projectsById: {} as IProjectsById,
    locations: [] as TLocations,
    locationsById: {} as ILocationsById,
    allLocations: [] as TLocations,
    tenants:[] as  ITenant[],
    connectors:[] as IConnector[],
    tenant2place: [] as ITenant2Place[],
    tenantsCTG: { } as  IDataTenants | null,
    connectorsCTG:{} as IDataConnectors | null,
    tenant2placeCTG: {} as ITenant2Place[],
    selectedTenant: null as ITenant | null,
    selectedLocation: null as ILocation | null,
    selectedProject: null as IProject | null,
    selectedProjectId: null as number | null,
    selectedLocationId: null as number | null,
    selectedConnector: null as IConnector | null,
    selectedConnectorDetail: null as TConnectorReq | null,
    selectedConnectorDetailCTG: null as TConnectorReq | null,
    messageList: [] as IMessageList
};

export type StoreType = typeof initialState;

export function useGeneralStore(externalState?: StoreType) {
    const {
        store: generalStore,
        getStore: getGeneralStore,
        updateStore: updateGeneralStore,
    } = useStore<StoreType>([STORE_KEY], externalState || { ...initialState });

    return { generalStore, getGeneralStore, updateGeneralStore };
}
