import { useEffect, useState } from 'react';
import { useParams } from 'react-router';


const useGetUrlParams = (nameVar: string) => {
    const params = useParams();
    const [id, setId] = useState<string | undefined>('');


    useEffect(() => {
        if (params && params[nameVar]) {
            setId(params[nameVar])
        }

    }, [params]);

    return id ? id : ''
}

export default useGetUrlParams