import {createTableColumn} from "@fluentui/react-components";
import { IColumn } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';


const columns:IColumn[] = [
    createTableColumn<IItemForTable>({
        columnId: "id",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.id.label).localeCompare(String(b.id.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "name",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.name.label).localeCompare(String(b.name.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "coordinates",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.coordinates.label).localeCompare(String(b.coordinates.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date from",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.date_from.label).localeCompare(String(b.date_from.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date to",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.date_to.label).localeCompare(String(b.date_to.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "timezone",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.timezone.label).localeCompare(String(b.timezone.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "address",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.address.label).localeCompare(String(b.address.label));
        },
    }),
];

export default columns