import { Spinner } from '@fluentui/react-components';
import type { SpinnerProps } from '@fluentui/react-components';
import { GlobalSpinnerWrapper } from './styles';
import { useGeneralStore } from 'src/store';
import { useTranslation } from 'react-i18next';

export const GlobalSpinner: React.FC = () => {
    const { t } = useTranslation();

    const {
        generalStore: { globalSpinners },
    } = useGeneralStore();
    return globalSpinners.length ? (
        <GlobalSpinnerWrapper>
            <Spinner />
            {globalSpinners.map((text) => {
                return <div key={text}>{`${t(text)}...`}</div>;
            })}
        </GlobalSpinnerWrapper>
    ) : null;
};
