import { IMessage } from '../../../types';
import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { useGeneralStore } from '../../../store';
import { useEffect } from 'react';

export const ItemMessage = (props: { item: IMessage }) => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const {  messageList } = getGeneralStore();

    useEffect(() => {
        let timer: undefined | NodeJS.Timeout = undefined;
        timer = setTimeout(removeMessage, 10000)
        return () => clearTimeout(timer);
    }, [messageList]);

    const removeMessage = () => {
        const array = messageList.filter((entry) => entry.uuid !== props.item.uuid)
        if (messageList.length > 0) {
            updateGeneralStore({
                messageList: array
            })
        }

    }

    const dismissMessage = (messageId: string) => () => {
        const array = messageList.filter((entry) => entry.uuid !== messageId)
        updateGeneralStore({
            messageList: [
                ...array
            ]
        })
    }

    return (
        <MessageBar

            layout={'singleline'}
            intent={'error'}
        >
            <MessageBarBody>
                <MessageBarTitle>{props.item.title}</MessageBarTitle>
                {props.item.text}
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button
                        onClick={dismissMessage(props.item.uuid)}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                    />
                }
            >
            </MessageBarActions>
        </MessageBar>
    );
};