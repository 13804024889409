import { useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { useGeneralStore } from 'src/store';

export const useAuth = () => {
    const currentUrl = window.location.origin + window.location.pathname;
    // const { urlsByServices } = useSelector(generalReducerValues);
    const { updateGeneralStore } = useGeneralStore();

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [searchParams] = useSearchParams();
    const { generalStore } = useGeneralStore();
    const { services } = generalStore;

    useEffect(() => {
        if (services && Object.keys(services).length > 0) {
            findUrlParams();
        }
    }, [services]);

    const findUrlParams = () => {
        const urlParams = Object.fromEntries(searchParams);

        if (urlParams?.['token']) {
            const token = urlParams?.['token'];
            token && localStorage.setItem('xtoken', token);
            token && updateGeneralStore({ token });
            removeParamsFromUrl(urlParams);
            return;
        } else if (!urlParams?.['token']) {
            const localToken = localStorage.getItem('xtoken');
            if (localToken) {
                updateGeneralStore({ token: localToken });
                return;
            } else {
                const authService = services['core/admin-service'];
                authService && redirectToAuth(authService?.AUTH_URL);
            }
        }
    };

    const removeParamsFromUrl = (urlParams: { [k: string]: string }) => {
        if (Object.keys(urlParams).length) {
            navigate(location.pathname);
        }
    };

    const redirectToAuth = (authUrl: string): void => window.location.replace(`${authUrl}?next=${currentUrl}`);
};
