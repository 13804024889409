import { Checkbox, Input } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { DivWrapperInputsBaseSC, DivWrapperInputsCustomSC } from '../styles';
import React, { useEffect, useState } from 'react';
import { useGeneralStore } from '../../../store';
import { InputText } from './InputText';
import { InputCheckBox } from './InputCheckBox';
import { InputsCustomObj } from '../settings/inputsCustomList';
import SelectComponentBox from './SelectComponentBox';
import { IObjDepend, IStateKey } from '../interfaces';
import CodeWrapper from './CodeWrapper';

export const CustomInputs = () => {
    const { t } = useTranslation();
    const { getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    const [objKey, setObjKey] = useState<IStateKey>({});

    useEffect(() => {
        if (selectedConnectorDetail) {
            let obj: IStateKey = {}
            Object.keys(selectedConnectorDetail).forEach((key, i) => {

                if (key === 'dependencies') {

                    Object.values(selectedConnectorDetail[key]).forEach((item, io) => {

                        item.fields.forEach((item2, i2) => {
                            obj[item2] = true
                        })

                    })
                }

            })
            setObjKey(obj)
        }

    }, [selectedConnectorDetail]);


    // TODO разобраться с типом
    return (
        <DivWrapperInputsCustomSC>
            {
                selectedConnectorDetail ? Object.keys(selectedConnectorDetail).map((item, i) => {

                    if (InputsCustomObj[item]) {
                        if (selectedConnectorDetail.dependencies[item]) {

                            let objDepend: IObjDepend = {}
                            selectedConnectorDetail.dependencies[item].fields.forEach((item2, i2) => {
                                let string: string = ''
                                Object.keys(selectedConnectorDetail).forEach((item3) => {
                                    if (item2 === item3) {

                                        // @ts-ignore
                                        string = selectedConnectorDetail[item3];
                                    }

                                })
                                objDepend[item2] = string;
                            })
                            return (
                                <SelectComponentBox
                                    key={`sfgerwasfg${i}`}
                                    label={InputsCustomObj[item].label}
                                    multiple={selectedConnectorDetail.dependencies[item].multiple ?
                                        true :
                                        false}
                                    required={objKey[InputsCustomObj[item].keyName]}
                                    // @ts-ignore
                                    defaultValue={selectedConnectorDetail[item]}
                                    connectorType={selectedConnectorDetail.connector_type}
                                    dependencies={objDepend}
                                    id={selectedConnectorDetail.id}
                                    keyName={InputsCustomObj[item].keyName}
                                />
                            )
                        }

                        else if (InputsCustomObj[item].type === 'text') {

                            return(
                                <InputText
                                    key={`sfgerwasfg${i}`}
                                    type={'text'}
                                    // @ts-ignore
                                    value={selectedConnectorDetail[item]}
                                    label={InputsCustomObj[item].label}
                                    required={objKey[InputsCustomObj[item].keyName]}
                                    keyName={InputsCustomObj[item].keyName}
                                />
                            )
                        }
                        else if (InputsCustomObj[item].type === 'checkbox') {
                            return(
                                <InputCheckBox
                                    key={`sfgerwasfg${i}`}

                                    // @ts-ignore
                                    checked={selectedConnectorDetail[item]}
                                    label={InputsCustomObj[item].label}
                                    keyName={InputsCustomObj[item].keyName}
                                />
                            )
                        }
                        else if (InputsCustomObj[item].type === 'number') {
                            return(
                                <InputText
                                    key={`sfgerwasfg${i}`}
                                    type={'number'}
                                    // @ts-ignore
                                    value={selectedConnectorDetail[item]}
                                    label={InputsCustomObj[item].label}
                                    keyName={InputsCustomObj[item].keyName}
                                />
                            )
                        }
                        else if (InputsCustomObj[item].type === 'number[]') {
                            return(
                                <InputText
                                    key={`sfgerwasfg${i}`}
                                    type={'number'}
                                    // @ts-ignore
                                    value={selectedConnectorDetail[item]}
                                    label={InputsCustomObj[item].label}
                                />
                            )
                        }
                        else if (InputsCustomObj[item].type === 'code') {
                            return(
                                <CodeWrapper
                                    key={`sfgerwasfg${i}`}
                                    label={InputsCustomObj[item].label}
                                    // type={'number'}
                                    // @ts-ignore
                                    value={selectedConnectorDetail[item]}
                                    keyName={InputsCustomObj[item].keyName}
                                    // label={InputsCustomObj[item].label}
                                />
                            )
                        }
                    }

                }) : null
            }
        </DivWrapperInputsCustomSC>
    );
};