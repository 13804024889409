import {Route, Routes, Navigate} from "react-router-dom";
import {Projects} from "./components/Projects/Projects";
import React, {FC} from "react";
import { Locations } from './components/Locations/Locations';
import { Connectors } from './components/Connectors/Connectors';

export const AppRouter: FC = () => {
    return (
        <Routes>

            <Route path="/" element={<Navigate to="/projects" />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:projectId" element={<Locations />} />
            <Route path="/project/:projectId/pl/:plId" element={<Connectors />} />

        </Routes>
    );
};