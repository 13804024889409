import { DivWrapperInput } from '../styles';
import { Label, Input, useId, InputOnChangeData } from '@fluentui/react-components';
import { FC, ChangeEvent } from 'react';
import { IPropsInputText } from '../interfaces';
import { useGeneralStore } from '../../../store';


export const InputText: FC<IPropsInputText> = (props) => {
    const inputId = useId("input");
    const {
        label,
        value,
        type,
        required= false,
        keyName = ''
    } = props

    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    const onChange = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {

        if (selectedConnectorDetail) {
            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: data.value === '' ? null : data.value
                }
            })
        }

    }

    return (
        <DivWrapperInput>
            <Label required={required} htmlFor={inputId}>
                {label}
            </Label>
            <Input onChange={onChange} type={type} id={inputId} value={value ? value : ''} />
        </DivWrapperInput>
    );
};