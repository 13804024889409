import { useEffect } from 'react';
import { useGeneralStore } from 'src/store';

type Args = { isFetching: boolean; text?: string };

export const useAddGlobalSpinner = ({ isFetching, text = '' }: Args) => {
    const { updateGeneralStore, generalStore } = useGeneralStore();

    useEffect(() => {
        if (isFetching) {
            updateGeneralStore({ globalSpinners: [...generalStore.globalSpinners, text] });
        } else {
            updateGeneralStore({ globalSpinners: generalStore.globalSpinners.filter((str) => str !== text) });
        }
    }, [isFetching]);
};
