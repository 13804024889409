import styled from 'styled-components';
import { projectTheme } from 'src/theme';

export const Wrapper = styled.div`
    width: 400px;
    display: flex;
    align-items: center;
    * {
        margin-top: 0 !important;
        color: ${projectTheme.colorBrandForeground1} !important;
        :hover {
            color: ${projectTheme.colorBrandForeground2} !important;
            background: transparent !important;
        }
    }
`;