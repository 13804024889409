import {
    DivWrapperCheckBoxesSC,
    DivWrapperFilterSC,
    DivWrapperOptionsPanelSC,
    DivWrapperSelectSC,
    InputFilterWrapperSC,
} from '../styles';
import { Search } from '../../Search/Search';
import { Checkbox, Select, useId } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IPropsPanelOptionsTenants } from '../interfaces';

export const PanelOptionsTenants: FC<IPropsPanelOptionsTenants> = (props) => {
    const { t } = useTranslation();
    const selectId = useId();

    const {
        searchOnChange,
        isCheckedDelete,
        onCheckDelete,
        isCheckedActual,
        onCheckActual,
        onChangeSelect,
        valueSelect
    } = props

    return (
        <InputFilterWrapperSC>
            <DivWrapperOptionsPanelSC>
                <DivWrapperFilterSC>
                    <Search placeholder={t('Search')} handleChange={searchOnChange} />
                    <DivWrapperCheckBoxesSC>
                        <Checkbox checked={isCheckedDelete} onChange={onCheckDelete} label={t('Show deleted')} />
                        <Checkbox checked={isCheckedActual} onChange={onCheckActual} label={t('Show nonactual')} />
                    </DivWrapperCheckBoxesSC>
                    <DivWrapperSelectSC>
                        <label htmlFor={selectId}>{t('Connectors')}</label>
                        <Select onChange={onChangeSelect} value={valueSelect} id={selectId}>
                            <option value={'All'}>{t('All')}</option>
                            <option value={'WithActualConnectors'}>{t('With actual connectors')}</option>
                            <option value={'WithoutConnectors'}>{t('Without connectors')}</option>
                            <option value={'WithSimultaneousConnectors'}>
                                {t('With simultaneous connectors')}
                            </option>
                            <option value={'WithSimultaneousActualConnectors'}>
                                {t('With simultaneous actual connectors')}
                            </option>
                        </Select>
                    </DivWrapperSelectSC>
                </DivWrapperFilterSC>

            </DivWrapperOptionsPanelSC>
        </InputFilterWrapperSC>
    );
};