import { Checkbox } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IPropsInputCheckbox } from '../interfaces';
import { DivWrapperCheckboxInner, DivWrapperCheckboxSC } from '../styles';
import { useGeneralStore } from '../../../store';

export const InputCheckBox: FC<IPropsInputCheckbox> = (props) => {
    const { t } = useTranslation();

    const {
        label,
        checked,
        keyName
    } = props
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (selectedConnectorDetail) {
            updateGeneralStore({
                selectedConnectorDetail: {
                    ...selectedConnectorDetail,
                    [keyName]: event.target.checked
                }
            })
        }

    }

    return (
        <DivWrapperCheckboxSC>
            <DivWrapperCheckboxInner>
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    label={t(label)}
                />
            </DivWrapperCheckboxInner>

        </DivWrapperCheckboxSC>
    );
};