import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useGeneralStore } from '../../../store';
import {
    TableColumnId,
    useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
    CheckboxOnChangeData,
    SelectProps,
} from '@fluentui/react-components';
import { IItemForTable } from '../../Table/interfaces';
import getItemsForTable from '../tools/getItemsForTable';
import { useTranslation } from 'react-i18next';
import { columns } from '../settings/Columns';
import {

    DivWrapperSideSC,
} from '../styles';
import { CustomTable } from '../../Table/CustomTable';
import { CustomSpinner } from '../../Spinner/Spinner';
import { RenderRow } from './RenderRow';
import { getItemsAfterFilter } from '../tools/getItemsAfterFilter';
import { filterTenants } from '../tools/funcById';
import { Title } from '../../Title/Title';
import { PanelOptionsTenants } from './PanelOptionsTenants';

export const TenantsPanel: FC<{ isShowSpinner: boolean, delta: number }> = ({ isShowSpinner, delta }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [heightContent, setHeightContent] = useState<number>(0);
    const { tenants, connectors, selectedTenant } = getGeneralStore();
    const [isCheckedDelete, setIsCheckedDelete] = useState<boolean>(false);
    const [isCheckedActual, setIsCheckedActual] = useState<boolean>(false);
    const [valueSelect, setValueSelect] = useState<string>('All');
    const { t } = useTranslation();


    const itemsTenantTable: IItemForTable[] = getItemsForTable(
        getItemsAfterFilter({
            array: filterTenants({ tenants, connectors, statusFilter: valueSelect }),
            isChecked1: isCheckedDelete,
            isChecked2: isCheckedActual,
        }),
        searchValue,
    );

    const {
        getRows,
        selection: { toggleRow, isRowSelected },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns,
            items: itemsTenantTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );

    useEffect(() => {
        window.addEventListener('resize', onResize);
        setHeightContent(window.innerHeight - (182 + delta));
    }, [delta]);

    const onResize = (e: any) => {
        setHeightContent(e.currentTarget?.innerHeight - (182 + delta));
    };

    const searchOnChange = (value: string) => {
        setSearchValue(value);
    };

    const onClickItem = (id: string) => {

        const tenant = tenants.find((item, i) => item.id === Number(id))

        if (tenant?.id === selectedTenant?.id) {
            updateGeneralStore({selectedTenant: null})
            return
        }
        updateGeneralStore({selectedTenant: tenant})

    };

    const rows = sort(
        getRows((row) => {
            const selected = isRowSelected(row.rowId);
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    onClickItem(String(row.item.id.label));
                    toggleRow(e, row.rowId);
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const createStylesPres = () => {
        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };

    const onCheckDelete = (e: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setIsCheckedDelete(Boolean(data.checked));
    };

    const onCheckActual = (e: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
        setIsCheckedActual(Boolean(data.checked));
    };

    const onChangeSelect: SelectProps['onChange'] = (event, data) => {

        setValueSelect(data.value);
    };

    return (
        <DivWrapperSideSC id={'tenantPanel'}>
            <Title>
                {t('Tenants')}
            </Title>
            <CustomSpinner text={'tenants and connectors'} isShow={isShowSpinner} />

            <PanelOptionsTenants
                searchOnChange={searchOnChange}
                isCheckedDelete={isCheckedDelete}
                onCheckDelete={onCheckDelete}
                isCheckedActual={isCheckedActual}
                onCheckActual={onCheckActual}
                onChangeSelect={onChangeSelect}
                valueSelect={valueSelect}
            />
            <CustomTable
                columns={columns}
                rows={rows}
                headerSortProps={headerSortProps}
                style={createStylesPres()}
                height={heightContent}
                iItemProjectsForTables={itemsTenantTable}
                RenderRowInner={RenderRow}
            />
        </DivWrapperSideSC>
    );
};
