import { TLocations } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';

const getItemsForTable = (list: TLocations, filterValue: string):IItemForTable[]  => {

    return list
        .filter((it) => it.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            String(it.id).toLowerCase().includes(filterValue.toLowerCase()))
        .map((item) => {
            return {
                name: {
                    label: item.name,
                },
                locationId: {
                    label: item.id,
                },
                projectsId: {
                    label: item.project_id,
                }
            };
        });
}

export default getItemsForTable;