import {createTableColumn} from "@fluentui/react-components";
import { IColumn } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';

const columns:IColumn[] = [
    createTableColumn<IItemForTable>({
        columnId: "name",
        compare: (a:IItemForTable, b:IItemForTable) => {

            return String(a.name.label).localeCompare(String(b.name.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "id",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a.id.label).localeCompare(String(b.id.label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date from",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a['date from'].label).localeCompare(String(b['date from'].label));
        },
    }),
    createTableColumn<IItemForTable>({
        columnId: "date to",
        compare: (a:IItemForTable, b:IItemForTable) => {
            return String(a['date to'].label).localeCompare(String(b['date to'].label));
        },
    }),
];

export default columns