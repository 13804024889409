import { Checkbox, Input } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { DivWrapperInputsBaseSC } from '../styles';
import React from 'react';
import { useGeneralStore } from '../../../store';
import { InputText } from './InputText';
import { InputsListBase } from '../settings/inputsList';
import { InputCheckBox } from './InputCheckBox';
import { InputsCustomObj } from '../settings/inputsCustomList';
import CalendarBox from './CalendarBox';
import CheckboxList from './CheckboxList';

export const BaseInputs = () => {
    const { t } = useTranslation();
    const { getGeneralStore } = useGeneralStore();
    const {
        selectedConnectorDetail
    } = getGeneralStore()

    // TODO разобраться с типом
    return (
        <DivWrapperInputsBaseSC>
            {
                selectedConnectorDetail ? InputsListBase.map((item, i) => {

                    if (item.type === 'text') {

                        return(
                            <InputText
                                key={`sfgerwasfg${i}`}
                                type={'text'}
                                // @ts-ignore
                                value={selectedConnectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                            />
                        )
                    }
                    else if (item.type === 'checkbox') {
                        return(
                            <InputCheckBox
                                key={`sfgerwasfg${i}`}

                                // @ts-ignore
                                checked={selectedConnectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                            />
                        )
                    }
                    else if (item.type === 'number') {
                        return(
                            <InputText
                                key={`sfgerwasfg${i}`}
                                type={'number'}
                                // @ts-ignore
                                value={selectedConnectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                            />
                        )
                    }
                    else if (item.type === 'timesList') {
                        return(
                            <CheckboxList
                                key={`sfgerwasfg${i}`}
                                // @ts-ignore
                                values={selectedConnectorDetail[item.keyName]}
                                label={item.label}
                                keyName={item.keyName}
                            />
                        )
                    }
                    else if (item.type === 'date') {
                        return(
                            <CalendarBox
                                key={`sfgerwasfg${i}`}
                                label={item.label}
                                dateG={item.keyName === 'date_to' ? selectedConnectorDetail.date_from ? selectedConnectorDetail.date_from
                                    : '' : selectedConnectorDetail.date_to ? selectedConnectorDetail.date_to : ''}

                                // @ts-ignore
                                value={selectedConnectorDetail[item.keyName]}
                                keyName={item.keyName}
                            />
                        )
                    }
                }) : null
            }
        </DivWrapperInputsBaseSC>
    );
};