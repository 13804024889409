import { LocationsModalContent } from '../LocationsModalContent/LocationsModalContent';
import Modals from '../Modal/Modals';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGeneralStore } from '../../store';
import { DateTime } from 'luxon';
import { ButtonsWrapper, ContentWrapper, Wrapper } from './styles';
import { Button } from '@fluentui/react-components';

export const TokenExpiration = () => {
    const { t } = useTranslation();
    const [modalOptions, setModalOptions] = useState<{
        show: boolean
    }>({
        show: false
    });
    const { getGeneralStore } = useGeneralStore();

    const { currentData } = getGeneralStore();



    useEffect(() => {
        getExpire()

        const interval = setInterval(getExpire, 600000);

        return () => clearTimeout(interval);
    }, [modalOptions, currentData?.token_expires]);

    const getExpire = () => {
        if (!currentData?.token_expires) {
            // setModalOptions({ ...modalOptions, show: true });
            return
        };
        const now = DateTime.now();
        const diff = DateTime.fromISO(currentData?.token_expires).diff(now, ['minutes', 'seconds']);
        const minutesToExpire = diff.toObject().minutes || 60;

        if (minutesToExpire < 15) {
            onRestart();
        } else if (minutesToExpire < 60) {
            if (!modalOptions.show) {
                setModalOptions({ ...modalOptions, show: true });
            }
        }
    }


    const onRestart = () => {
        localStorage.removeItem('xtoken');
        window.location.reload();
    };


    const closeModal = () => {
        setModalOptions(prevState => ({
            ...prevState,
            show: false
        }))
    }



    return (
        <Modals
            title={t('Session expiration')}
            modalOptions={modalOptions}
            closeModal={closeModal}
        >
            <Wrapper>
                <ContentWrapper>
                    <div>{t('Your session will expire soon! You need to restart browser.')}</div>
                </ContentWrapper>
                <ButtonsWrapper>
                    <Button
                        onClick={closeModal}
                        appearance="outline"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        appearance="primary"
                        onClick={onRestart}
                    >
                        {t('Restart')}
                    </Button>
                </ButtonsWrapper>
            </Wrapper>
        </Modals>
    );
};