import styled from 'styled-components';

export const LocationsWrapperSC = styled.section<{
    $bgcolor: string;
    $color: string;
}>`
    display: grid;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${(p) => p.$bgcolor};
    color: ${(p) => p.$color};
    padding: 10px;
    grid-template-columns: 100%;
    grid-template-rows: 32px 1fr;
    box-sizing: border-box;
    gap: 10px;
`

