import { IItemListInputs } from '../interfaces';

export const InputsCustomObj: { [key : string]: IItemListInputs } = {
    login: {
        type: 'text',
        label: 'Login',
        keyName: 'login',
    },
    password: {
        type: 'text',
        label: 'Password',
        keyName: 'password',
    },
    place_address: {
        type: 'text',
        label: 'Place address',
        keyName: 'place_address',
    },
    api_key: {
        type: 'text',
        label: 'Api key',
        keyName: 'api_key',
    },
    organization: {
        type: 'text',
        label: 'Organization',
        keyName: 'organization',
    },
    place_id: {
        type: 'text',
        label: 'Place id',
        keyName: 'place_id',
    },
    email: {
        type: 'text',
        label: 'Email',
        keyName: 'email',
    },
    inn: {
        type: 'text',
        label: 'Inn',
        keyName: 'inn',
    },
    kkts: {
        type: 'text',
        label: 'Kkts',
        keyName: 'kkts',
    },
    source_tenant_id: {
        type: 'text',
        label: 'Source tenant id',
        keyName: 'source_tenant_id',
    },
    coefficient: {
        type: 'text',
        label: 'Coefficient',
        keyName: 'coefficient',
    },
    country: {
        type: 'text',
        label: 'Country',
        keyName: 'country',
    },
    city: {
        type: 'text',
        label: 'City',
        keyName: 'city',
    },
    project: {
        type: 'text',
        label: 'Project',
        keyName: 'project',
    },
    cm_id: {
        type: 'text',
        label: 'Cm id',
        keyName: 'cm_id',
    },
    port: {
        type: 'text',
        label: 'Port',
        keyName: 'port',
    },
    active_hash: {
        type: 'text',
        label: 'Active hash',
        keyName: 'active_hash',
    },
    token: {
        type: 'text',
        label: 'Token',
        keyName: 'token',
    },
    service_host: {
        type: 'text',
        label: 'Service host',
        keyName: 'service_host',
    },
    integrator_id: {
        type: 'text',
        label: 'Integrator id',
        keyName: 'integrator_id',
    },

    config: {
        type: 'code',
        label: 'Config',
        keyName: 'config',
    },

    user_id: {
        type: 'text',
        label: 'User id',
        keyName: 'user_id',
    },
    kkms: {
        type: 'text',
        label: 'Kkms',
        keyName: 'kkms',
    },
    alias: {
        type: 'text',
        label: 'Alias',
        keyName: 'alias',
    },
    inn_tenant: {
        type: 'text',
        label: 'Inn tenant',
        keyName: 'inn_tenant',
    },
    inn_project: {
        type: 'text',
        label: 'Inn project',
        keyName: 'inn_project',
    },
    kpp_project: {
        type: 'text',
        label: 'Kpp project',
        keyName: 'kpp_project',
    },
    address: {
        type: 'text',
        label: 'Address',
        keyName: 'address',
    },

    agreement_number: {
        type: 'text',
        label: 'Agreement number',
        keyName: 'agreement_number',
    },
    kktSalesPoint: {
        type: 'text',
        label: 'Kkt Sales Point',
        keyName: 'kktSalesPoint',
    },
    name: {
        type: 'text',
        label: 'Name',
        keyName: 'name',
    },

}