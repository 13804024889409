import {
    Input,
    InputOnChangeData
} from "@fluentui/react-components";
import React, {FC, useState} from "react";
import {ISearchProps} from "./interfaces";

export const Search: FC<ISearchProps> = (props) => {
    const {
        placeholder,
        handleChange = undefined
    } = props
    const [inputValue, setInputValue] = useState<string | undefined>('');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {

        setInputValue(data.value)
        handleChange && handleChange(data.value)
    }

    return (
        <Input
            placeholder={placeholder}
            onChange={onChange}
            value={inputValue}
        />
    );
};