import { TLocations } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';

const getItemsForTable = (list: TLocations, filterValue: string):IItemForTable[]  => {

    return list
        .filter((it) => it.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            String(it.id).toLowerCase().includes(filterValue.toLowerCase()))
        .map((item) => {
            return {
                id: {
                    label: item.id,
                },
                name: {
                    label: item.name,
                },
                coordinates: {
                    label: `${item.latitude},${item.longitude}`,
                },
                date_from: {
                    label: item.date_from ? item.date_from : '-',
                },
                date_to: {
                    label: item.date_to ? item.date_to : '-',
                },
                timezone: {
                    label: item.timezone,
                },
                address: {
                    label: item.address,
                },
            };
        });
}

export default getItemsForTable;