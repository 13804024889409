import { useEffect, useState } from 'react';


const useGetTenantsListWidth = () => {
    const [width, setWidth] = useState<number>(0);


    useEffect(() => {
        let width: number = 0
        if (!localStorage.getItem('tenantsListWidth')) {
            localStorage.setItem('tenantsListWidth', '50')
        }
        width = Number(localStorage.getItem('tenantsListWidth'))
        setWidth(width)
    }, []);

    return width
}


export default useGetTenantsListWidth;
