import { FC, useEffect, useState } from 'react';
import { InputFilterWrapperSC, ProjectsWrapperSC } from './styles';
import { projectTheme } from 'src/theme';
import {
    TableColumnId,
    useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
    Button
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useGeneralStore } from '../../store';
import { CustomTable } from '../Table/CustomTable';
import columns from './settings/Columns';
import { Search } from '../Search/Search';
import { useNavigate } from 'react-router-dom';
import { IItemForTable } from '../Table/interfaces';
import Modals from '../Modal/Modals';
import { LocationsModalContent } from '../LocationsModalContent/LocationsModalContent';
import getItemsForTable from './tools/getItemsForTable';


export const Projects: FC = () => {
    const { colorNeutralBackground1, colorNeutralForeground3Hover } = projectTheme;
    const { t } = useTranslation();
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [heightContent, setHeightContent] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const navigate = useNavigate();
    const [modalOptions, setModalOptions] = useState<{
        show: boolean
    }>({
        show: false
    });

    const { getGeneralStore } = useGeneralStore();

    const { projects } = getGeneralStore();

    const itemsProjectTable: IItemForTable[] = getItemsForTable(projects, searchValue);

    const {
        getRows,
        selection: { toggleRow },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns,
            items: itemsProjectTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );


    useEffect(() => {
        window.addEventListener('resize', onResize);
        setHeightContent(window.innerHeight - 145);
    }, []);

    const onResize = (e: any) => {
        setHeightContent(e.currentTarget?.innerHeight - 145);
    };

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });
    const onClickItem = (id:string) => {
        navigate(`/project/${id}`);
    }
    const rows = sort(
        getRows((row) => {
            const selected = false;
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    onClickItem(String(row.item.id.label))
                    toggleRow(e, row.rowId)
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );

    const createStylesPres = () => {
        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };

    const searchOnChange = (value: string) => {
        setSearchValue(value);
    };

    const closeModal = () => {
        setModalOptions(prevState => ({
            ...prevState,
            show: false
        }))
    }

    const showModal = () => {
        setModalOptions(prevState => ({
            ...prevState,
            show: true
        }))
    }

    return (
        <ProjectsWrapperSC $bgcolor={colorNeutralBackground1} $color={colorNeutralForeground3Hover}>
            <InputFilterWrapperSC>
                <Search placeholder={t('Search')} handleChange={searchOnChange} />
                <Button appearance="primary" onClick={showModal}>
                    {t('View all locations')}
                </Button>
            </InputFilterWrapperSC>
            <CustomTable
                columns={columns}
                rows={rows}
                headerSortProps={headerSortProps}
                style={createStylesPres()}
                height={heightContent}
                iItemProjectsForTables={itemsProjectTable}
            />
            <Modals
                title={t('All locations')}
                modalOptions={modalOptions}
                closeModal={closeModal}
            >
                <LocationsModalContent/>
            </Modals>
        </ProjectsWrapperSC>
    );
};
