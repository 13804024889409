import styled from 'styled-components';
import { makeStyles, tokens } from '@fluentui/react-components';

export const DivWrapperAlertSC = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 101;
`;

export const useStyles = makeStyles({
    messageBarGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',

        height: 'max-content',
        overflow: 'auto',
    },
});
