import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import { useMutation } from '@tanstack/react-query';
import { postRequest } from '../tools/request';
import { IBodyConnectorParams, TConnectorParams } from '../components/Connectors/interfaces';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const usePostConnectorParams = (id: number, keyName: string) => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { token, services, messageList } = getGeneralStore();

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].CONNECTOR_PARAMS}`;
    };
    const mutationFn = (body: IBodyConnectorParams) =>
        postRequest({
            url: getUrl(services),
            token: token,
            data: body,
        });

    const { data, isPending, mutate } = useMutation<TConnectorParams, unknown, IBodyConnectorParams, any>({
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4()
                    }
                ]
            })
        },
    });

    return { isPending, mutate, data };
};
