import {Table, TableBody, TableHeader, TableRow} from "@fluentui/react-components";

import {FC} from "react";
import {CustomHeaderCellSC, TableWrapperSC} from "../Projects/styles";
import {FixedSizeList as List} from "react-window";
import {RenderRow} from "./components/RenderRow";
import {IPropsTable} from "./interfaces";

export const CustomTable:FC<IPropsTable> = (props) => {
    const {
        headerSortProps,
        columns,
        rows,
        RenderRowInner = null
    } = props



    return (
        <TableWrapperSC>
            <Table
                noNativeElements={true}
                arial-label="Default table"
                aria-rowcount={rows.length}
            >
                <TableHeader>
                    <TableRow aria-rowindex={1}>
                        {
                            columns.map((item, i) => {
                                return (
                                    <CustomHeaderCellSC
                                        {...headerSortProps(item.columnId)}
                                        key={`column.columnKey${i}`}>
                                        {item.columnId}
                                    </CustomHeaderCellSC>
                                )
                            })
                        }

                        <div role="presentation" style={props.style}/>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <List
                        // height={props.height}
                        height={props.height}
                        itemCount={props.iItemProjectsForTables.length}
                        itemSize={45}
                        width="100%"
                        itemData={rows}
                    >
                        {RenderRowInner ? RenderRowInner : RenderRow}
                    </List>

                </TableBody>
            </Table>
        </TableWrapperSC>
    );

}