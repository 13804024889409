import { GENERAL, LOCATIONS } from '../constants';
import { useGeneralStore } from '../store';
import { ILocationsById, TLocations, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from '../hooks';
import { v4 as uuidv4 } from 'uuid';

export const useGetLocations = (projectID: string) => {
    const queryKey = [GENERAL, LOCATIONS, projectID];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: TLocations) => response;


    const getUrl = (_services: UrlsByServices, _projectID: string): string => {
        return `${_services['core/admin-service'].PROJECT_LOCATIONS_URL}?project_id=${_projectID}`
    }
    const queryFn = () =>
        getRequest({
            url: getUrl(services, projectID),
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });
    const enabled = Boolean(token) && Boolean(id) && Boolean(projectID);

    const { data, isFetching, error } = useQuery<TLocations, unknown, TLocations, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (!data) {
            return;
        }
        updateGeneralStore({ locations: data });
        let obj: ILocationsById = {}
        data.forEach((item, index) => {
            obj[item.id] = item;
        })
        updateGeneralStore({ locationsById: obj });

    }, [data, updateGeneralStore]);
    useAddGlobalSpinner({ isFetching, text: 'Loading locations' });
}