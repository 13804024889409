import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FluentProvider } from '@fluentui/react-components';

import App from './App';
import './i18n';
import { projectTheme } from './theme';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
});

const root = createRoot(document.getElementById('root')!);

root.render(
    <FluentProvider theme={projectTheme}>
        <QueryClientProvider client={queryClient}>
            <Router >
                <ReactQueryDevtools initialIsOpen={false} />
                <App />
            </Router>
        </QueryClientProvider>
    </FluentProvider>,
);
