import {projectTheme} from "../../../theme";
import {CustomTableRowSC} from "../../Projects/styles";
import {TableCell, TableCellLayout} from "@fluentui/react-components";
import { FC} from "react";
import { IPropsRenderRow } from '../interfaces';
import formatText from '../../../tools/formatText';
import { TableCellLayoutSC } from '../styles';


export const RenderRow: FC<IPropsRenderRow> = ({index, style, data}) => {


    const {item, selected, appearance, onClick, onKeyDown} = data[index];
    const {colorBrandBackground2, colorNeutralForegroundStaticInverted} = projectTheme;

    const bg = index % 2 !== 0 ? colorBrandBackground2 : colorNeutralForegroundStaticInverted


    const listComponents = Object.keys(item).map((_item, i) => {
        return (
            <TableCell
                key={`item table row${i}`}
            >
                <TableCellLayoutSC >
                    {formatText(item[_item].label, 30)}
                </TableCellLayoutSC>

            </TableCell>
        )
    })

    return (
        <CustomTableRowSC
            key={`item.file.label${index}`}
            aria-rowindex={index + 2}
            style={style}
            onKeyDown={onKeyDown}
            onClick={onClick}
            appearance={appearance}
            $bg={bg}
        >
            {listComponents}
        </CustomTableRowSC>
    )
}