import {
    DivBoxFilterSC,
    DivWrapperCheckBoxesSC,
    DivWrapperFilterConnectorsListSC,
    DivWrapperOptionsPanelSC,
} from '../styles';
import { Search } from '../../Search/Search';
import { Button, Checkbox } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IPropsPanelOptionsConnectors } from '../interfaces';

export const PanelOptionsConnectors: FC<IPropsPanelOptionsConnectors> = (props) => {
    const { t } = useTranslation();

    const {
        searchOnChange,
        isCheckedActual,
        onCheckActual,
        showModal,
        disabledButton
    } = props

    return (
        <DivWrapperOptionsPanelSC>
            <DivWrapperFilterConnectorsListSC
                $itemWidth={400}
            >
                <DivBoxFilterSC
                    $position={'start'}
                    $count={2}
                >
                    <Search
                        placeholder={t('Search')}
                        handleChange={searchOnChange}
                    />

                    <DivWrapperCheckBoxesSC>
                        <Checkbox
                            checked={isCheckedActual}
                            onChange={onCheckActual}
                            label={t('Show nonactual')}
                        />
                    </DivWrapperCheckBoxesSC>
                </DivBoxFilterSC>
                <DivBoxFilterSC
                    $position={'end'}
                    $count={1}
                >
                    <Button
                        appearance="primary"
                        onClick={showModal}
                        disabled={disabledButton}
                    >
                        {t('Add new connector')}
                    </Button>

                </DivBoxFilterSC>



            </DivWrapperFilterConnectorsListSC>

        </DivWrapperOptionsPanelSC>
    );
};