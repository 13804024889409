import { IProjects, ITenant } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';


const getItemsForTable = (list: ITenant[], filterValue: string):IItemForTable[]  => {

    return list
        .filter((it) => it.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            String(it.id).toLowerCase().includes(filterValue.toLowerCase()))
        .map((item) => {

            return {

                id: {
                    label: item.id,
                },
                name: {
                    label: item.name,
                },
                is_deleted: {
                    label: Boolean(!item.is_active),
                },
                is_actual: {
                    label: Boolean(item.is_actual)
                },
                connector_count: {
                    label: `${ item.connectors_count ? item.connectors_count : '0' }/${item.connectorsCountNotActive ? item.connectorsCountNotActive : 0}`,
                },

            };
        });
}

export default getItemsForTable;