import { IProjects } from '../../../types';
import { IItemForTable } from '../../Table/interfaces';

const getItemsForTable = (list: IProjects, filterValue: string):IItemForTable[]  => {

    return list
        .filter((it) => it.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            String(it.id).toLowerCase().includes(filterValue.toLowerCase()))
        .map((item) => {
            return {
                name: {
                    label: item.name,
                },
                id: {
                    label: item.id,
                },
                'date from': {
                    label: item.date_from ? item.date_from : '-',
                },
                'date to': {
                    label: item.date_to ? item.date_to : '-',
                },
            };
        });
}

export default getItemsForTable;