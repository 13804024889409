import useGetUrlParams from '../../hooks/useGetUrlParams';
import { useGetLocations } from '../../api/useGetLocations';
import { useGeneralStore } from '../../store';
import { LocationsWrapperSC } from './styles';
import { projectTheme } from '../../theme';
import { Search } from '../Search/Search';
import { InputFilterWrapperSC } from '../Projects/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import columns from './settings/Columns';
import { CustomTable } from '../Table/CustomTable';
import {
    TableColumnId, useFluent,
    useScrollbarWidth,
    useTableFeatures,
    useTableSelection,
    useTableSort,
} from '@fluentui/react-components';
import { IItemForTable } from '../Table/interfaces';
import getItemsForTable from './tools/getItemforTable';
import { useNavigate } from 'react-router-dom';

export const Locations = () => {
    const projectId = useGetUrlParams('projectId')
    useGetLocations(projectId)
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { t } = useTranslation();
    const { locations} = getGeneralStore();
    const { colorNeutralBackground1, colorNeutralForeground3Hover } = projectTheme;
    const [searchValue, setSearchValue] = useState<string>('');
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [heightContent, setHeightContent] = useState<number>(0);
    const navigate = useNavigate();
    const itemsLocationTable: IItemForTable[] = getItemsForTable(locations, searchValue);

    const {
        getRows,
        selection: { toggleRow },
        sort: { getSortDirection, toggleColumnSort, sort },
    } = useTableFeatures(
        {
            columns,
            items: itemsLocationTable,
        },
        [
            useTableSelection({
                selectionMode: 'single',
                defaultSelectedItems: new Set([0, 1]),
            }),
            useTableSort({
                defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
            }),
        ],
    );

    useEffect(() => {
        updateGeneralStore({selectedProjectId: Number(projectId)})
    }, [projectId]);
    useEffect(() => {
        window.addEventListener('resize', onResize);
        setHeightContent(window.innerHeight - 145);
    }, []);

    const onResize = (e: any) => {
        setHeightContent(e.currentTarget?.innerHeight - 145);
    };


    const onClickItem = (id:string) => {
        navigate(`/project/${projectId}/pl/${id}`);
    }

    const rows = sort(
        getRows((row) => {
            const selected = false;
            return {
                ...row,
                onClick: (e: React.MouseEvent) => {
                    onClickItem(String(row.item.id.label))
                    toggleRow(e, row.rowId)
                },
                onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === ' ') {
                        e.preventDefault();
                        toggleRow(e, row.rowId);
                    }
                },
                selected,
                appearance: selected ? ('brand' as const) : ('none' as const),
            };
        }),
    );

    const headerSortProps = (columnId: TableColumnId) => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const createStylesPres = () => {

        return {
            width: heightContent < rows.length * 45 ? scrollbarWidth : 0,
        };
    };





    const searchOnChange = (value: string) => {
        setSearchValue(value);
    };

    const closeSharedPresetModal = () => {
        // setSharedPresetModalStatus({ show: false });
    };

    return (
        <LocationsWrapperSC $bgcolor={colorNeutralBackground1} $color={colorNeutralForeground3Hover}>
            <InputFilterWrapperSC>
                <Search placeholder={t('Search')} handleChange={searchOnChange} />
            </InputFilterWrapperSC>
            <CustomTable
                columns={columns}
                rows={rows}
                headerSortProps={headerSortProps}
                style={createStylesPres()}
                height={heightContent}
                iItemProjectsForTables={itemsLocationTable}
            />

        </LocationsWrapperSC>
    );
};