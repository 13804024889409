import React from 'react';
import { useAuth, useInitialRequests } from 'src/hooks';
import './App.css';
import { GlobalSpinner } from './components/GlobalSpinner/GlobalSpinner';
import {Header} from "./components/Header/Header";
import {AppRouter} from "./AppRouter";
import { TokenExpiration } from './components/TokenExpiration/TokenExpiration';
import { Alert } from './components/Alert/Alert';

function App() {
    useInitialRequests();
    useAuth();


    return (
        <div className="App">
            <Header/>
            <GlobalSpinner />
            <AppRouter/>
            <TokenExpiration/>
            <Alert/>
        </div>
    );
}

export default App;
